.RunListContainer{
    padding-top: 0;
    margin-top: 0;
    background-color: #252931;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.RunListContainer ul{
    border-left: none;
}
.RunListContainer li{
   color: white; 
   align-self: baseline;
   margin: 0;
   padding: 0;
}