.Button{
    background-color: white;
    max-height: 40px;
    max-width: 175px;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border-width: 0;
    justify-self: center;
    align-self: center;
}
.Button:hover{
    cursor: pointer;
}
.Button:disabled{
    cursor: default;
    background-color: gray;
}