html {
    background-color: #252931;
}
header{
    background-color: #252931;
    height: 10vh;
}
header .link-logo:hover{
    cursor: pointer;
}
.Container{
    padding-top: 0;
    padding-bottom: 10vh;
    margin-top: 0;
    background-color: #252931;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Text{
    color: #252931;
    font-weight: bold;
    font-size: 2em;
}
h1{
    margin-top: 0;
    color: white;
    font-size: 3em;
}
h2, h3{
    color: white;
    margin-right: 0px;
    padding-right: 0px;
}
h2{
    margin: 5px;
}
.StatusContainer{
    display: flex;
    align-items: center;
    justify-self: end;
    align-self: center;
    margin: 0;
    padding: 0;
    height: 40px;
}
ul{
    width: auto;
    margin: 5px 0px;
    padding: 5px 15px;
    border-left: 1px solid white;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}
li{
   color: white; 
   align-self: baseline;
   margin-bottom: 2px;
}