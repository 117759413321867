.Home {
  text-align: center;
}

.Home-logo {
  height: 40vmin;
  pointer-events: none;
}

.link-logo{
  height: 50%;
  margin-right: 5%;
  margin-left: 5%;
  min-width: 40px;
}

.link-text{
  color: lightgrey;
  margin-right: 0;
  text-decoration: none;
  min-width: 100px;
  /* background-color: #61dafb; */
}
.link-box{
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* justify-items: stretch; */
  margin-right: 0;
  padding-right: 0;
  text-decoration: none;
  min-width: 400px;
  height: 75px;
  width: 400px;
  border: 1px solid rgb(100, 100, 125);
  border-width: 1px;
  border-radius: 10px;
  margin-top: 9px;
  margin-bottom: 1px;
}

.link-box:hover{
  border-width: 2px;
  border-color:white;
  margin-top: 8px;
  margin-bottom: 0px;
  background-color: #21232b;
}

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-spin infinite 20s linear;
  }
}

.Home-header {
  background-color: #252931;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Home-link {
  color: #61dafb;
}

@keyframes shake {
  0% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

@keyframes fallAndFade {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  40% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}


@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
